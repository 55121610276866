import React from 'react';
import './Header.css';

const Header = ({ theme, onSelectCategory, toolsCount, streamersCount }) => {

  const domain = process.env.REACT_APP_DOMAIN;
  let header = "Path of Exile Tools";
  let site = "poetools.net";

  if (domain === 'diablotools.uk') {
    header = "Diablo Tools"
    site = "diablotools.uk";
  }

  return (
      <header className={`${theme}`}>
        <h1>{header}</h1>
        <ul className="nav-links">
          <li>|</li>
          <li>
            <a href="#tools" onClick={() => onSelectCategory('tools')}>
              Tools <span className="bubble-count">{toolsCount}</span>
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="#streamers" onClick={() => onSelectCategory('streamers')}>
              Streamers <span className="bubble-count">{streamersCount}</span>
            </a>
          </li>
          <li>|</li>
          <li>
            <a href="https://ko-fi.com/giorgosart" target="_blank" rel="noopener noreferrer">
              Support {site}
            </a>
          </li>
          <li>|</li>
        </ul>
      </header>
  );
}

export default Header;
