import React, { useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ToolCard from './ToolCard';
import Filter from './Filter';
import SearchBar from './SearchBar';
import NoResults from './NoResults';
import NoPinnedTools from './no-pinned-tools/NoPinnedTools';
import './ToolList.css';
import ThemeContext from "./ThemeContext";
import useTools from '../hooks/useTools';
import usePinnedTools from '../hooks/usePinnedTools';

const ToolList = ({ data }) => {
  const { tools, categories } = useTools(data);
  const { pinnedTools, togglePin } = usePinnedTools();
  const [selectedCategories, setSelectedCategories] = useState(['All']);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeKeywords, setActiveKeywords] = useState([]);
  const { theme } = useContext(ThemeContext);

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategories(prevSelected => {
      if (category === 'All') return ['All'];
      if (category === 'Pinned') return ['Pinned'];
      if (prevSelected.includes(category)) {
        const newSelected = prevSelected.filter(c => c !== category);
        return newSelected.length === 0 ? ['All'] : newSelected;
      }
      return prevSelected.includes('All') ? [category] : [...prevSelected, category];
    });
  }, []);

  const handleSearchChange = useCallback((query) => {
    setSearchQuery(query);
    setActiveKeywords(query.split(' ').filter(word => word.length > 0).map(word => word.toLowerCase()));
  }, []);

  const handleKeywordClick = useCallback((keyword) => {
    setActiveKeywords(prevKeywords => {
      if (!prevKeywords.includes(keyword)) {
        const newKeywords = [...prevKeywords, keyword];
        setSearchQuery(newKeywords.join(' '));
        return newKeywords;
      }
      return prevKeywords;
    });
  }, []);

  const filteredTools = useMemo(() => {
    return tools.filter(tool => {
      const matchesCategory = selectedCategories.includes('All') ||
          (selectedCategories.includes('Pinned') && pinnedTools.includes(tool.name)) ||
          tool.categories.some(category => selectedCategories.includes(category));

      const toolKeywords = tool.keywords.map(keyword => keyword.toLowerCase());
      const matchesSearch = tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          activeKeywords.every(keyword => toolKeywords.some(toolKeyword => toolKeyword.includes(keyword)));

      return matchesCategory && matchesSearch;
    });
  }, [tools, selectedCategories, searchQuery, activeKeywords, pinnedTools]);

  return (
      <div className="tool-list-container">
        <SearchBar
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
        />
        <Filter
            categories={categories}
            selectedCategories={selectedCategories}
            onCategoryChange={handleCategoryChange}
        />
        <div className="tool-list">
          {filteredTools.length > 0 ? (
              filteredTools.map((tool, index) => (
                  <ToolCard
                      key={index}
                      tool={tool}
                      theme={theme}
                      isPinned={pinnedTools.includes(tool.name)}
                      onTogglePin={togglePin}
                      onKeywordClick={handleKeywordClick}
                  />
              ))
          ) : (
              selectedCategories.includes('Pinned') ? (
                  <NoPinnedTools theme={theme} />
              ) : (
                  <NoResults theme={theme} />
              )
          )}
        </div>
      </div>
  );
};

ToolList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
};

export default ToolList;
