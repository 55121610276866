import { useState, useEffect } from 'react';

const usePinnedTools = () => {
  const [pinnedTools, setPinnedTools] = useState(() => {
    const saved = localStorage.getItem('pinnedTools');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem('pinnedTools', JSON.stringify(pinnedTools));
  }, [pinnedTools]);

  const togglePin = (toolName) => {
    setPinnedTools((prevPinned) => {
      if (prevPinned.includes(toolName)) {
        return prevPinned.filter(name => name !== toolName);
      } else {
        return [...prevPinned, toolName];
      }
    });
  };

  return { pinnedTools, togglePin };
};

export default usePinnedTools;
