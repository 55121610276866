import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ReportModal.css';

const ReportModal = ({ onClose, tool, theme, header, description }) => {
  const [reportText, setReportText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      tool_name: tool.name,
      report_text: reportText,
    };

    emailjs.send('service_t5tbhfl', 'template_nlq8drl', templateParams, 'qALEP6L5kkkD206u5')
    .then((response) => {
      onClose();
    }, (err) => {
      console.error('FAILED...', err);
    });
  };

  return (
      <div className="modal-overlay">
        <div className={`modal-content ${theme}`}>
          <h2>{header}</h2>
          <form onSubmit={handleSubmit}>
            <p>{description}</p>
            <textarea
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
                required
                rows="5"
            />
            <div className="modal-actions">
              <button type="button" onClick={onClose}>Cancel</button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
  );
}

export default ReportModal;
