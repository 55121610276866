import React from 'react';
import './NoPinnedTools.css';
import noPinsImage from '../../assets/pinning-example.png';

const NoPinnedTools = ({theme}) => {
  return (
      <div className={`no-pinned-tools ${theme}`}>
        <h1>No Pinned Tools</h1>
        <p>You haven't pinned any tools yet.</p>
        <p>To pin a tool, click the bookmark icon on the tool card.</p>
        <img src={noPinsImage} alt="Example of pinning a tool"
             className="pin-example"/>
      </div>
  );
}

export default NoPinnedTools;
