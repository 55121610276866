import React, { useContext, useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import ToolList from './components/ToolList';
import ThemeToggle from "./components/theme-toggle/ThemeToggle";
import './App.css';
import './dark-theme.css';
import './light-theme.css';
import ThemeContext from "./components/ThemeContext";
import poeToolsData from './data/tools.json';
import diabloToolsData from './data/diablo-tools.json';
import ReactGA from "react-ga4";
import streamersPOEData from './data/streamers.json';
import streamersDiabloData from './data/diablo-streamers.json';

const initializeGoogleAnalytics = () => {
  const domain = process.env.REACT_APP_DOMAIN;
  const trackingId = domain === 'diablotools.uk' ? "G-S6JT0P0PY9" : "G-QQYN9YT011";
  ReactGA.initialize(trackingId);
};

const getInitialData = (domain, category) => {
  let toolsData = domain === 'diablotools.uk' ? diabloToolsData : poeToolsData;
  let streamersData = domain === 'diablotools.uk' ? streamersDiabloData : streamersPOEData;

  if (category === 'tools') {
    return { data: toolsData, toolCount: toolsData.length, streamerCount: streamersData.length };
  } else if (category === 'streamers') {
    return { data: streamersData, toolCount: toolsData.length, streamerCount: streamersData.length };
  }
  return { data: toolsData, toolCount: toolsData.length, streamerCount: streamersData.length };
};

function App() {
  const { theme } = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [toolCount, setToolCount] = useState(0);
  const [streamerCount, setStreamerCount] = useState(0);
  const domain = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    initializeGoogleAnalytics();
    const initialData = getInitialData(domain, 'tools');
    setData(initialData.data);
    setToolCount(initialData.toolCount);
    setStreamerCount(initialData.streamerCount);
  }, [domain]);

  const handleSelectCategory = (category) => {
    const categoryData = getInitialData(domain, category);
    setData(categoryData.data);
    setToolCount(categoryData.toolCount);
    setStreamerCount(categoryData.streamerCount);
  };

  return (
      <div id="root" className="App">
        <Header
            theme={theme}
            onSelectCategory={handleSelectCategory}
            toolsCount={toolCount}
            streamersCount={streamerCount}
        />
        <ThemeToggle />
        <main className="main-content">
          <ToolList data={data} />
        </main>
        <Footer theme={theme} />
      </div>
  );
}

export default App;
