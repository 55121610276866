import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';
import './ThemeToggle.css';
import sunIcon from '../../assets/sun.png';
import moonIcon from '../../assets/half-moon.png';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
      <div className="theme-toggle-container">
        <input
            type="checkbox"
            id="theme-toggle"
            className="theme-toggle-checkbox"
            checked={theme === 'dark'}
            onChange={toggleTheme}
        />
        <label htmlFor="theme-toggle" className="theme-toggle-label">
        <span className="theme-toggle-ball">
          <img
              src={theme === 'light' ? sunIcon : moonIcon}
              alt={theme === 'light' ? 'Sun icon' : 'Moon icon'}
              className="theme-toggle-icon"
          />
        </span>
        </label>
      </div>
  );
};

export default ThemeToggle;
