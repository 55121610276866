import React from 'react';
import './Filter.css';

const Filter = ({ categories, selectedCategories, onCategoryChange }) => {
  const isSelected = (category) => selectedCategories.includes(category);

  return (
      <div className="filter-buttons">
        {['Pinned', ...categories].map((category, index) => (
            <button
                key={index}
                className={`filter-button ${isSelected(category) ? 'selected' : ''}`}
                onClick={() => onCategoryChange(category)}
            >
              {category}
            </button>
        ))}
      </div>
  );
}

export default Filter;
