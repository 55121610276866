import React, { useState } from 'react';
import './NoResults.css';
import noResultsImage from '../assets/not-found.png';
import ReportModal from './ReportModal';

const NoResults = ({ theme }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
      <div className={`no-results ${theme}`}>
        <h1>No results</h1>
        <img src={noResultsImage} alt="No results found" className={`no-results-image ${theme}`} />
        <p>
          If you think a tool or a streamer is missing, please
          <button className="report-link" onClick={handleOpenModal}>
            let us know
          </button>!
        </p>
        {isModalOpen && <ReportModal onClose={handleCloseModal} tool={{ name: 'Missing Tool' }} theme={theme} header="Missing Entry" description="Please provide us with the URL of the entry you want added."/>}
      </div>
  );
};

export default NoResults;
