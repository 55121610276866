import React from 'react';
import './SearchBar.css';
import clearIcon from '../assets/clear.png';

const SearchBar = ({ searchQuery, onSearchChange }) => {
  return (
      <div className="search-bar">
        <input
            type="text"
            placeholder="Search by name or keyword..."
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
        />
        <button className="clear-button" onClick={() => onSearchChange('')}>
          <img src={clearIcon} alt="Clear"/>
        </button>
      </div>
  );
}

export default SearchBar;
