import React from 'react';
import './Footer.css';

const Footer = ({ theme }) => {
  const domain = process.env.REACT_APP_DOMAIN;
  let company = "Grinding Gear Games";
  let site = "Path of Exile Tools";

  if (domain === 'diablotools.uk') {
    company = "Activision Blizzard, Inc"
    site = "Diablo Tools";
  }

  return (
      <footer className={`${theme}`}>
        <p>&copy; 2024 {site} | v1.3.0</p>
        <p>This product isn't affiliated with or endorsed by {company} in any way.</p>
      </footer>
  );
}

export default Footer;
