import React, {useState} from 'react';
import './ToolCard.css';
import pinIcon from '../assets/bookmark.png';
import unpinIcon from '../assets/bookmark-empty.png';
import externalLinkIcon from '../assets/redirect.png';
import reportIcon from '../assets/broken.png';
import officialIcon from '../assets/official.png';
import notWorkingIcon from '../assets/not-working.png';
import Tooltip from './tooltip/Tooltip';
import ReportModal from './ReportModal';

const ToolCard = ({tool, theme, isPinned, onTogglePin, onKeywordClick}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOfficial = tool.keywords.includes('#official');

  const handleReportClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
      <div className={`tool-card ${theme}`}>
        {!tool.maintained && <img
            className="not-working"
            src={notWorkingIcon}
            alt='Not Working'
        />}
        <div
            className="pin-icon-wrapper"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => onTogglePin(tool.name)}
        >
          <img
              className={`pin-icon ${!isPinned && theme === 'dark' ? 'dark'
                  : ''}`}
              src={isPinned ? pinIcon : unpinIcon}
              alt={isPinned ? 'Unpin' : 'Pin'}
          />
          {showTooltip && (
              <Tooltip message={isPinned ? 'Unpin it!' : 'Pin it!'}/>
          )}
        </div>
        <div className="tool-content">
          <div className="image-container">
            <img className="tool-image" src={tool.image} alt={tool.name}/>
            {isOfficial && <img className="official-icon" src={officialIcon}
                                alt="Official"/>}
          </div>
          <div className="tool-details">
            <div className="tool-heading">{tool.name}</div>
            <span className="categories">{tool.categories.join(', ')}</span>
          </div>
        </div>
        <p>{tool.description}</p>
        <div className="keywords">
          {tool.keywords.map((keyword, index) => (
              <span
                  key={index}
                  className="keyword"
                  onClick={() => onKeywordClick(keyword)}
              >
            {keyword}
          </span>
          ))}
        </div>
        <div className="tool-card-actions">
          <a href={tool.url} target="_blank" rel="noopener noreferrer">
            Visit
            <img src={externalLinkIcon} alt="External Link"
                 className="external-link-icon"/>
          </a>
          <a href="#" className="report-button" onClick={handleReportClick}>
            <img src={reportIcon} alt="Report"/>
          </a>
        </div>
        {isModalOpen && <ReportModal onClose={handleCloseModal} tool={tool}
                                     theme={theme} header="Report Link Issue"
                                     description="Please describe the issue with the link:"/>}
      </div>
  );
}

export default ToolCard;
