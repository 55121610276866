import { useState, useEffect } from 'react';

const useTools = (initialData) => {
  const [tools, setTools] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setTools(initialData);
    const uniqueCategories = ['All', ...new Set(initialData.flatMap(tool => tool.categories))];
    setCategories(uniqueCategories);
  }, [initialData]);

  return { tools, categories };
};

export default useTools;
